import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude", "place_id"];

  connect() {
    setTimeout(() => { 
    if (typeof google != "undefined") {
          this.initMap();
        }
      }, 2000)      
    }

  parseInfoWindowHTML(input){
    if(!input){
      return ''
    }
    var parser = new DOMParser();
    var node = parser.parseFromString(input,"text/xml");

    var linkNode = node.querySelector('a');
    if(!linkNode){
      return ''
    }
    var link = linkNode.getAttribute('href');
    var companyId = link.split('/').pop()
    return companyId;
  }

  toRad(Value) {
    return Value * Math.PI / 180;
  }

  updateInfoBox(info, distance, icon = undefined){
    if(icon){
      return info
    }
    const parsedDistance = parseFloat(distance).toFixed(2);
    const splitAt = info.indexOf('</div></div>')
    const infoSectionA = info.substring(0, splitAt)
    const infoSectionB = info.substring(splitAt)
    const informationInjecetion = `<p>Distance: ${parsedDistance}km</p>`
    return infoSectionA+informationInjecetion+infoSectionB
  }

  calculateDistance(lat1, lon1, lat2, lon2, toRad) {
    var R = 6371; // km
    var dLat = toRad(lat2-lat1);
    var dLon = toRad(lon2-lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
  }

  initMap() {
    try{
    var lat = Number(this.data.get("latitude"));
    var long = Number(this.data.get("longitude"));
    var zoom = Number(this.data.get("zoom"));

    var placeId = this.data.get("placeId");

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(lat, long),
      zoom: zoom
    });

    var mapType = this.data.get("maptype");

    if (mapType == "markers") {
      this.drawClusterer(this.map);
    } 
    else if (mapType == 'lead_markers'){
      var range = Number(this.data.get("range"));

      this.leadSurroundingLocations(this.map, lat, long, this.calculateDistance, this.toRad, this.updateInfoBox, this.parseInfoWindowHTML, range);
    }
    else {
      this.marker = new google.maps.Marker({
        map: this.map,
        position: { lat: lat, lng: long }
      });
    }
  }
  catch(e){
    console.log(e)
  }
  }

  initialize() {}






  leadSurroundingLocations(map, lat, long, calculateDistance, toRad,updateInfoBox, parseInfoWindowHTML, range){
    // Looking to replace to this icon later but needed something in the mean time
    var icon = "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
    var locations = JSON.parse(this.data.get("markers"));
    console.log(locations)
    locations.push({ lat: lat, lng: long, icon, infowindow: "<div>Lead Location</div>" })
    var locationRangeLimit = range;
    
    locations.map(function(location, i) {
      var distance = calculateDistance(lat, long, location.lat, location.lng, toRad)


      var marker = null;

      if(location?.icon){
        var marker = new google.maps.Marker({
          map,
          icon: location.icon,
          position: { lat: location.lat, lng: location.lng }
        });
      }
      else{
        var marker = new google.maps.Marker({
          map,
          position: { lat: location.lat, lng: location.lng }
        });

      }

      var info = updateInfoBox(location.infowindow, distance, location?.icon)

      var infoWindow = new google.maps.InfoWindow({
        content: info
      });

      if(distance <= locationRangeLimit){
        var name = parseInfoWindowHTML(location.infowindow);
        console.log(name, ' logg')
        if(name.length){        
          try{
            var parsed_distance = distance === 0 ? '0' : distance.toFixed(2).split('.').pop()
            console.log(`div.map_location.id_${name}_${parsed_distance}`)
            var row = document.querySelector(`div.map_location.id_${name}_${parsed_distance}`)
            if(row){
              row.addEventListener('click', e => {
                infoWindow.open(map, marker);
              })
            }
          }
          catch(e){
          }
        } 
      }

      marker.addListener("click", function() {
        infoWindow.open(map, marker);
      });
      return marker;
    });

  }

  drawClusterer(map) {
    var locations = JSON.parse(this.data.get("markers"));

    var markers = locations.map(function(location, i) {
      var marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng }
      });
      var infoWindow = new google.maps.InfoWindow({
        content: location.infowindow
      });
      marker.addListener("click", function() {
        infoWindow.open(map, marker);
      });
      return marker;
    });

    var markerCluster = new MarkerClusterer(map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    });
  }
}